<template>
  <div class="detailPage">
    <topbar :title="title"></topbar>
    <div class="body" v-if="couponInfo">
      <div class="coupon">
        <div class="top">
          <div class="cover"><img :src="couponInfo.imageUrl" alt="" /></div>
          <div class="top-right">
            <div class="tagBox">
              <p class="tag devicry" v-if="couponInfo.tagType == 2">
                <i></i>
                <span
                  >{{ $store.state.language == 1 ? "外送速遞" : "Delivery" }}
                </span>
              </p>
              <p class="tag tagApp" v-if="couponInfo.tagType == 3">
                <i></i>
                <span
                  >{{ $store.state.language == 1 ? "App兌換" : "By App" }}
                </span>
              </p>
              <p class="tag" v-else-if="couponInfo.tagType == 1">
                <i></i>
                <span>{{
                  $store.state.language == 1 ? "餐廳兌換" : "In Store"
                }}</span>
              </p>
            </div>
            <p>{{ couponInfo.voucherName }}</p>
            <p class="score">
              {{ couponInfo.point
              }}{{ $store.state.language == 1 ? "積分" : "Points" }}
            </p>
            <p class="time">
              {{ $store.state.language == 1 ? "有效期" : "Valid until" }}:{{
                couponInfo.date
              }}
            </p>
          </div>
        </div>
        <div class="line"></div>
        <div class="btn" @click="showBox">
          {{ $store.state.language == 1 ? "兌換" : "Redeem" }}
        </div>
      </div>
      <p class="title">
        {{ $store.state.language == 1 ? "條款及細則" : "Terms and Conditions" }}
      </p>
      <div class="desc">
        {{ couponInfo.termCondition }}
      </div>
    </div>
    <confir-box
      v-show="showDH"
      :show="showBtn"
      :showSolt="true"
      :content="YHXQ_alert"
      :finishBtn="finishBtn"
      :cancelBtn="cancelBtn"
      @finish="finish"
      @closeBox="showBox"
    >
      <template v-slot:title>
        <p class="YHXQ_alert" v-if="couponInfo.point < scroe">
          {{ YHXQ_alert_one }}
          <span>{{ couponInfo.point + " " + YHXQ_alert_four }}</span
          >{{ YHXQ_alert_two }}
        </p>
        <p class="YHXQ_alert" v-else>
          {{ YHXQ_point_none }}
        </p>
      </template>
    </confir-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import ConfirBox from "../../components/common/CnfirmBox.vue";
import language from "../../assets/js/language";
import { postPaas } from "../../assets/utils/request";
export default {
  components: { Topbar, ConfirBox },
  data() {
    return {
      scroe: null,
      couponInfo: null,
      showDH: false,
      showBtn: true,
      title:
        this.$store.state.language == 1 ? "兌換詳情" : "Redemption Details",
      YHXQ_alert: "",
      YHXQ_alert_one: "",
      YHXQ_alert_two: "",
      YHXQ_alert_four: "",
      YHXQ_point_none: "",
      finishBtn: "",
      cancelBtn: "",
      HQXXSB:""
    };
  },
  methods: {
    //  兑换优惠券
    async scoreCoupon() {
      let params = {
        actionName: "candao.member.voucherRedeemableRedeem",
        content: {
          voucherId: this.couponInfo.voucherId,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.showDH = false;
        setTimeout(() => {
          this.$router.back(-1);
        }, 500);
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    finish() {
      if (this.couponInfo.point > this.scroe) {
        this.showDH = false;
        this.$router.back(-1);
        return;
      }
      this.scoreCoupon();
    },
    showBox() {
      this.showDH = !this.showDH;
    },
    setLanguage() {
      var T = "chinese";
      if (this.$store.state.language == 2) {
        T = "english";
      }
      this.YHXQ_alert =
        language.YHXQ_alert_three[T] +
        " " +
        this.scroe +
        " " +
        language.YHXQ_alert_four[T];
      this.YHXQ_alert_one = language.YHXQ_alert_one[T];
      this.YHXQ_alert_two = language.YHXQ_alert_two[T];
      this.YHXQ_alert_four = language.YHXQ_alert_four[T];
      this.YHXQ_point_none = language.YHXQ_point_none[T];
      this.HQXXSB=language.HQXXSB[T]
      if (this.couponInfo.point > this.scroe) {
        this.YHXQ_alert = "";
        this.showBtn = false;
        this.finishBtn = this.$store.state.language == 1 ? "確認" : "Confirm";
      } else {
        this.finishBtn = this.$store.state.language == 1 ? "兌換" : "Redeem";
      }
      this.cancelBtn = this.$store.state.language == 1 ? "取消" : "Cancel";
    },
  },
  created() {
    var info = decodeURIComponent(this.$route.query.info);
    this.couponInfo = JSON.parse(info);
    this.scroe = this.$route.query.score;
    this.setLanguage();
  },
};
</script>
<style scoped>
.detailPage {
  background-color: #f2f3f5;
  min-height: 100vh;
}
.coupon {
  width: 3.43rem;
  margin: 0.16rem;
  background: url("../../assets/icon/cbg.png") no-repeat center;
  background-size: cover;
  padding: 0.16rem;
  box-sizing: border-box;
  border-radius: 0.12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 5px 20px 1px rgb(193, 194, 195);
}
.top {
  width: 100%;
  display: flex;
}
.cover {
  width: 1.44rem;
  height: 1.2rem;
  overflow: hidden;
  margin-right: 0.12rem;
}
.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-right {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #02050c;
}
.top-right p {
  text-align: justify;
  margin-bottom: 0.08rem;
}
.tagBox{
  display: flex;
}
.tagBox .tag+.tag{
  margin-left: .05rem;
}
.tag {
  height: 0.18rem;
  font-size: 0.1rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5e9eb;
  border-radius: 0.02rem;
  line-height: 0.18rem;
  padding: 0 0.05rem;
}
.tag i {
  width: 0.11rem;
  height: 0.11rem;
  background: url("../../assets/icon/g2.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.tagApp i {
  width: 0.068rem;
  height: 0.114rem;
  background: url("../../assets/icon/g1.png") no-repeat center;
  background-size: 0.068rem 0.114rem;
}
.tag.devicry {
  width: 0.7rem;
  background-color: #f2f3f5;
}
.tag.devicry i {
  width: 0.13rem;
  height: 0.1rem;
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
}
.score {
  color: #e3002b;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.2rem;
}
.time {
  color: #808080;
  font-size: 0.12rem;
  line-height: 0.17rem;
}
.line {
  width: 3.25rem;
  border: 0.01rem dashed #cacbcc;
  margin-top: 0.08rem;
}
.btn {
  width: 100%;
  height: 0.44rem;
  background: linear-gradient(135deg, #ff1212 0%, #e4022b 100%);
  margin-top: 0.22rem;
  border-radius: 0.12rem;
  line-height: 0.44rem;
  color: #fff;
  font-size: 0.14rem;
}
.title {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #03060d;
  text-align: left;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin: 0.14rem 0 0.08rem;
}
.desc {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #808080;
  text-align: justify;
  padding: 0 0.16rem;
  box-sizing: border-box;
  white-space: pre-line;
}
.YHXQ_alert {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.25rem;
  text-align: center;
  color: #03060d;
}
.YHXQ_alert span {
  color: #e4022b;
}
</style>